.has-main-nav {
    .global-network-container {
        display: none;
    }
}
html.collapsed-mobile-nav:not(.slide-nav-open) .theme-nav-style-slide {
    position: fixed;
}
html.has-se-bar.collapsed-mobile-nav .theme-nav-style-slide {
    top: 100px!important;
}

.has-mobile-nav {
    .global-network-container {
        display: none;
    }
    .custom-toggle {
        top: 5px;
    }
}
.collapsed-mobile-nav #topNav {
    padding-top: 0px;
}
html.collapsed-mobile-nav.slide-nav-open .theme-nav-style-slide {
    top: 110px!important;
}
html.has-se-bar.collapsed-mobile-nav.slide-nav-open .theme-nav-style-slide {
    top: 110px!important;
}
.has-se-bar .has-mobile-nav .slideNavCollapsed .collapsed-mobile-nav .theme-nav-style-slide{
    top: 50px!important;
}

html.collapsed-mobile-nav.slide-nav-open .noUserBar .theme-nav-style-slide {
    top:50px!important;
}